<template>
	<div class="dashboard">
		<a-descriptions
			:title="$route.meta.title"
			:column="4"
			layout="vertical"
			class="dashboard-top"
		>
			<a-descriptions-item label="公司总数">
				{{info.totalCompanyCount}}
			</a-descriptions-item>
			<a-descriptions-item label="电站在线/总数">
				{{info.onlineStationCount}}/{{info.totalStationCount}}
			</a-descriptions-item>
			<a-descriptions-item label="装机功率/容量">
				{{ info.installedPower
						? $utils.toFixed(info.installedPower / (1000 * 1000), 2)
						: "-"
				}}MW/{{ info.capacity
						? $utils.toFixed(info.capacity / (3600000 * 1000), 2)
						: "-"
				}}MWh
			</a-descriptions-item>
			<a-descriptions-item label="云存储已用空间">
				--
			</a-descriptions-item>
		</a-descriptions>
		<a-table
			rowKey="id"
			:columns="table_col"
			:data-source="table_data"
			:pagination="table_pagination"
			:loading="table_loading"
			@change="tableChange"
			style="margin-top: 16px"
		></a-table>
	</div>
</template>

<script>
	import {
		companyStatistic,
		companyStatisticPage
		} from "@/api/index";
	export default {
		name: "DashBoard",
		data() {
			return {
				info: {
					"totalCompanyCount" : "",
					"onlineStationCount" : "",
					"totalStationCount" : "",
					"installedPower": "",
					"capacity": "",
					"cloudStorageUsedSpace": "—"
				},
				table_col: [
					{
						title: "序号",
						dataIndex: "key",
						customRender: (text, record, index) => {
							return (
								(this.table_pagination.current - 1) *
									this.table_pagination.pageSize +
								(index + 1)
							);
						}
					},
					{	title: "公司简称", dataIndex: "companyName"  },
					{
						title: "电站在线/总数", 
						dataIndex: "stationCount",
						customRender: ( record) => {
							return record ? record : "---";
						}  
					},
					{
						title: "装机功率",
						dataIndex: "installedPower",
						customRender: record => {
							return record ? this.$utils.toFixed(record / (1000 * 1000), 2)+"MW" : "-";
						}
					},
					{
						title: "装机容量",
						dataIndex: "capacity",
						customRender: record => {
							return record ? this.$utils.toFixed(record / (3600000 * 1000), 2)+"MWh" : "-";
						}
					},
					{
						title: "账号数量",
						dataIndex: "userCount",
						customRender: ( record) => {
							return record ? record : "0";
						}
					},
					{
						title: "近七天访问数",
						dataIndex: "pv",
						customRender: ( record) => {
							return record ? record : "0";
						}
					},
					{
						title: "上次访问", dataIndex: "recentVisitTime",
						customRender: ( record) => {
							return record ? record : "-";
						}
					}
				]
			};
		},
		created() {

		},
		mounted() {
			this.getCompanyInfo();
			this.getTableList();
		},
		
		methods: {
			//公司统计信息
			getCompanyInfo() {
				companyStatistic({})
						.then(res => {
							if(res.data){
								const data = res.data;
								this.info = data;
							}
						});
			},
			
			// 公司列表
			getTableList() {
				this.table_loading = true;
		
				companyStatisticPage({
							current: this.table_pagination.current,
							pageSize: this.table_pagination.pageSize
						})
						.then(res => {
							let array = res.data && res.data.list;
							array.forEach((item) => {
								item.stationCount = item.onlineStationCount+"/"+item.totalStationCount;
							});
							this.table_data = array;
							this.table_pagination.total = res.data.total;
						})
						.catch(() => (this.table_data = []))
						.finally(() => (this.table_loading = false));
			}
		}
	};
</script>

<style lang="scss">
	.dashboard {
		&-top{
			margin-bottom: 20px;
			table{
				background-color:#fafafa;
				border: 1px solid #e8e8e8;
				border-radius: 2px;
				td{
					padding: 5px 10px;
					text-align: center;
					
					.ant-descriptions-item-colon{
						margin-top: 20px;
						font-size: 14px;
						&:after{
							content: "";
						}
					}
					
					.ant-descriptions-item-content{
						font-size: 24px;
						height: 70px;
						line-height: 70px;
					}
				}
			}
			
		}
	}
</style>
